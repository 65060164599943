.custom-stepper-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .custom-stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .custom-stepper .nav-link {
    position: relative;
    padding: 10px;
    font-weight: bold;
    color: #333;
    background-color: #f8f9fa;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .custom-stepper .nav-link.active {
    background-color: #8d65db;
    color: #fff;
  }
  
  .custom-stepper .nav-link.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .progress-line {
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    height: 4px;
    width: 0;
    background-color: #007bff;
    transition: width 0.3s ease-in-out;
  }

  .react-stepper-horizontal .step.active {
    background-color: #8d65db !important;
  }
  