.page_loader {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 5px solid #8d65db;
	border-top-color: #ffff;
	box-sizing: border-box;
	background: transparent;
	animation: loading 1s linear infinite;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	0% {
		transform: rotate(360deg);
	}
}
.btn-primary {
	color: #eef3ff !important;
	background-color: #8d65db !important;
	border-color: #8d65db !important;
	outline: #8d65db !important;
}
.btn-primary:hover {
	color: #eef3ff !important;
	background-color: #9273d1 !important;
	border-color: #9273d1 !important;
}
.btn-primary:focus {
	box-shadow: 0 0 0 0.25rem #8d65db !important;
}
.btn-outline {
	border-color: #8d65db;
}
.btn-close {
	color: #eef3ff !important;
	background-color: #9273d1 !important;
	border-color: #9273d1 !important;
}
.btn-close:focus {
	box-shadow: 0 0 0 0.25rem #8d65db !important;
}
.form-control:focus {
	border-color: #9273d1 !important;
}
.sidebar-link:hover .svg-icon {
	color: #8d65db !important;
}
.sidebar-item.active > .sidebar-link .svg-icon {
	color: #8d65db !important;
}
.sidebar-item.active > .sidebar-link {
	border-left: 2px solid #8d65db;
}
.breadcrumb-item a {
	color: #8d65db !important;
}
.breadcrumb-item a:hover {
	color: #9273d1 !important;
}
.form-select:focus {
	border-color: #9273d1;
}
.form-check-input:checked {
	background-color: #9273d1;
	border-color: #9273d1;
}
.form-check-input:focus {
	box-shadow: 0 0 0 0.25rem #8d65db !important;
	border-color: #9273d1 !important;
}
.text-primary {
	color: #8d65db !important;
}

.dropdown-menu-item:hover {
	background-color: #2d3035;
}

.bg-success {
	background-color: #46a35b !important;
}

.bg-danger {
	background-color: #bd5357 !important;
}

.text-danger {
	color: #bd5357 !important;
}

.bg-warning {
	background-color: #e3ae11a1 !important;
}

.dropdown-menu-button {
	height: "20px";
}

.dropdown-menu-button:focus {
	border: none;
	outline: none;
	box-shadow: none !important;
}

.form-field {
	/* margin-bottom: 5px !important; */
}

.validation-message {
	font-size: 14px !important;
}

.login-page .info {
	background: #9273d1 !important;
}
.table {
	color: #eef3ff !important;
}

.table-hover > tbody > tr:hover {
	color: #eef3ff !important;
}
body {
	color: #eef3ff !important;
}
.breadcrumb-item.active {
	color: #eef3ff;
}
nav#sidebar {
	color: #eef3ff !important;
}
.sidebar-link {
	color: #eef3ff !important;
}
.form-label {
	color: #eef3ff !important;
}
.quiz-view {
	color: #8a8d93;
}
.quiz-description {
	text-align: justify;
}
.quiz-header {
	color: #eef3ff !important;
}
.table-container {
	overflow-x: auto;
}
.btn-danger {
	color: #fff;
	background-color: #bd5357;
	border-color: #bd5357;
	outline: #bd5357 !important;
}
.btn-danger:hover {
	background-color: #bd5357;
	border-color: #bd5357;
	outline: #bd5357 !important;
}
.btn-danger:focus {
	background-color: #bd5357;
	border-color: #bd5357;
	outline: #bd5357 !important;
}

.tableFixHead {
	overflow-y: auto; 
	max-height: 550px;
}
.tableFixHead thead th {
	position: sticky;
	top: 0px; 
	background-color: #22252a !important;
}
table {
	border-collapse: collapse;
	width: 100%;
}
.sidebar-item.active ul .sidebar-link {
    border-left: 2px solid #8d65db !important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #4c4c4c !important;
    opacity: 1;
}
.highlightOption {
    background: "pink" !important;
    color: #fff;
}
.form-control, .form-select {
	font-weight: 300 !important;
	color: #eef3ff !important;
}
.custom-stepper a, .custom-stepper span {
	line-height: 31px !important;
}
.btn-dark:disabled {
	color: #eef3ff !important;
}

/* color: #eef3ff !important */
.imgView {
	display: flex;
	align-items: center;
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    border: #dee2e6;
    line-height: 3.5rem;
	justify-content: center;
}
.imgView img {
	border-radius: 4px;
}
.sample-file-btn {
	color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.sample-file-btn:hover {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.icon_down_dir::before {
	content: none !important;
}
.form-select:disabled {
    background-color: #4c4c4c;
}

.fontAptos {
    font-family: 'Aptos';
    font-style: normal;
}